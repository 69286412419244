import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import {
  loadSchedulingIfNeeded,
  chooseAppointment,
  calendarDateChanged,
  loadAvailableAppointments,
  cancelSelectedAppointment,
  clearAvailableAppointments,
  getConsultation,
  loadAvailableDates,
  clearAvailableDates,
} from 'ducks/scheduling';
import AppointmentCalendar from './AppointmentCalendar/AppointmentCalendar';
import './AppointmentDetails.scss';
import track, { TR_CALENDAR_PICK } from 'services/track';
import { getDurationString, getConsultationDuration } from 'services/utils';
import schedulingService from 'services/api/schedulingService';
import _ from 'lodash';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_SCHEDULING_CALENDAR_PAGE } from '../../../../services/gaTrack';
import Link from 'components/widgets/Link/Link';
import { openChat } from 'services/utils';
import chatIcon from './../../../../images/common/chat_24.svg';
import callIcon from './../../../../images/common/call_24.svg';
import emailIcon from './../../../../images/common/email_24.svg';

class AppointmentDetails extends Component {
  state = { confirmButtonEnabled: true, isMobile: false };

  componentDidMount() {
    const { isPreSelectedFlow, gotoSelectService } = this.props;
    gaTrack(GA_TR_SCHEDULING_CALENDAR_PAGE);
    window.scrollTo(0, 0);
    this.state.isMobile = schedulingService.isMobile();
    window.addEventListener('resize', this.onResize);
    const {
      calendarState,
      calendarTimezone,
      calendarSpeciality,
      calendarCountry,
      calendarProvider,
    } = this.props.scheduling;
    if (this.state.isMobile) {
      this.props.calendarDateChanged(moment().add(1, 'days').startOf('day').toDate());
    }
    if (calendarState && calendarTimezone && calendarSpeciality) {
      this.props.loadAvailableAppointments();
    }

    /* reset insurance when you change appointment service */
    schedulingService.removeUseInsurance();

    if (
      !isPreSelectedFlow &&
      !(calendarState && calendarTimezone && calendarSpeciality && calendarCountry)
    ) {
      gotoSelectService();
    }
    const { isOutreachAppointmentFlow } = schedulingService.getOutreachAppointmentData();

    if (isOutreachAppointmentFlow && !(calendarSpeciality && calendarCountry)) {
      gotoSelectService();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.props.dispatch(clearAvailableAppointments());
    this.props.dispatch(clearAvailableDates());
  }

  onSlotClick = (sid, start, end) => {
    const { scheduling, chooseAppointment } = this.props;
    const {
      calendarState,
      calendarTimezone,
      counselors,
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes,
      calendarModality,
      calendarCountry,
    } = scheduling;
    const consultation = getConsultation(
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes
    );

    chooseAppointment({
      start,
      end,
      provider: _.get(sid, 'providerId'),
      consultation: consultation,
      state: calendarState,
      timezone: calendarTimezone,
      vsee_specialty: _.get(sid, 'vsee_specialty', ''),
      modality: calendarModality,
      duration: _.get(sid, 'duration'),
      country: calendarCountry,
    });
    if (start) track(TR_CALENDAR_PICK, { date: start.toString() });
    this.onConfirm();
  };

  isCalendarShaded = () => {
    const { calendarState, calendarTimezone, calendarSpeciality } = this.props.scheduling;
    return !(calendarState && calendarTimezone && calendarSpeciality);
  };

  onDateChanged = (date) => {
    this.props.calendarDateChanged(date);
    this.props.loadAvailableAppointments();
  };

  onConfirm = () => {
    const { scheduleAction } = this.props;
    this.setState({ confirmButtonEnabled: false });
    return scheduleAction().then(
      () => {
        this.props.onConfirm && this.props.onConfirm();
      },
      () => this.setState({ confirmButtonEnabled: true })
    );
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  onCancel = () => {
    this.closeModal();
    return this.props.dispatch(cancelSelectedAppointment());
  };

  openModal = () => {
    return this.props.setAppointmentModalMode({ open: true });
  };

  closeModal = () => {
    return this.props.setAppointmentModalMode({ open: false });
  };

  onResize = () => {
    this.setViewMode();
  };

  getModalProps = () => {
    if (this.props.isRescheduling)
      return {
        header: (
          <span>
            You are about to reschedule your appointment.
            <br />
            Please confirm your new appointment.
          </span>
        ),
        confirmText: 'CONFIRM',
        cancelText: 'CANCEL',
      };
    return {
      header: 'Are you ready to schedule this appointment?',
      confirmText: 'CONFIRM',
      cancelText: 'RESCHEDULE',
    };
  };

  getDurationLabel = () => {
    const { slots } = this.props.scheduling;
    let durationString = undefined;
    if (!_.isEmpty(slots)) {
      const slot = _.head(slots);
      durationString = slot && slot.duration;
    }
    return durationString && getDurationString(durationString);
  };

  renderHeader() {
    const {
      scheduling: { selectedProduct },
    } = this.props;
    if (!selectedProduct) return null;
    return (
      <h3 key="calendar-subheader" className="header-dark v2">
        Schedule Your Appointment
      </h3>
    );
  }

  renderSelectedService = () => {
    const { isRescheduling } = this.props;
    const { selectedServiceDescriptor } = this.props.scheduling;
    const serviceLabel =
      selectedServiceDescriptor && selectedServiceDescriptor.isPackage && !isRescheduling
        ? `Service Selected: ${
            selectedServiceDescriptor.productName || selectedServiceDescriptor.title
          }`
        : null;
    return serviceLabel ? <div className="selected-service">{serviceLabel}</div> : null;
  };

  render() {
    const { scheduling, isRescheduling, toPreviousStep, me, isPreSelectedFlow } = this.props;
    const {
      selectedAppointment: appointment,
      schedulingError,
      slots,
      reservations,
      isSchedulingLoaded,
      isLoadingAvailability,
      isLoadingAvailableDates,
      calendarStartTime,
      selectedServiceDescriptor,
      availableDates,
    } = scheduling;
    const { partnerSchedulingPlatform } = me;

    return (
      <div className={classnames('appointment-details', { hidden: !isSchedulingLoaded })}>
        <div className="ap-calendar scp">
          {this.renderHeader()}
          <AppointmentCalendar
            slots={slots}
            availableDates={availableDates}
            reservations={reservations}
            date={calendarStartTime}
            onDateChanged={this.onDateChanged}
            onSlotClick={this.onSlotClick}
            isShaded={this.isCalendarShaded()}
            isLoading={isLoadingAvailability || isLoadingAvailableDates}
            isMobile={this.state.isMobile}
            isRescheduling={isRescheduling}
            toPreviousStep={toPreviousStep}
            isLoadingAvailableDates={isLoadingAvailableDates}
            isPreSelectedFlow={isPreSelectedFlow}
          />
          <Alert message={schedulingError} />

          <h6 className="header-need-help">Need more help?</h6>
          <div className="contact-mode-box-wrapper">
            <div className="contact-mode-box">
              <img src={callIcon} alt="Phone" />
              <span className="contact-mode">Call</span>
              <span>
                You can call our client support services at{' '}
                <span>
                  <a className="cc-phone" href="tel:+18776880992">
                    1 (877) 688-0992
                  </a>
                </span>
              </span>
            </div>

            <div className="contact-mode-box">
              <img src={emailIcon} alt="Email" />
              <span className="contact-mode">Email</span>
              <span>
                You can email our client support services at{' '}
                <a className="cc-email" href="mailto:clinical@genomemedical.com">
                  clinical@genomemedical.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppointmentDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  scheduling: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  scheduleAction: PropTypes.func.isRequired,
  isRescheduling: PropTypes.bool,
  calendarDateChanged: PropTypes.func.isRequired,
  chooseAppointment: PropTypes.func.isRequired,
  loadSchedulingIfNeeded: PropTypes.func.isRequired,
  loadAvailableAppointments: PropTypes.func.isRequired,
  setAppointmentModalMode: PropTypes.func.isRequired,
  newPrice: PropTypes.number,
  webinar: PropTypes.object,
  onConfirm: PropTypes.func,
  consultation: PropTypes.object,
};

const mapStateToProps = ({
  scheduling,
  scheduling: { selectedServiceDescriptor, selectedProduct, consultationTypes },
  modals,
  webinar,
  user,
}) => ({
  scheduling,
  modals,
  webinar: webinar.data,
  consultation: getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes),
  me: user.me,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadAvailableAppointments: () => dispatch(loadAvailableAppointments()),
  loadAvailableDates: () => dispatch(loadAvailableDates()),
  calendarDateChanged: (date) => dispatch(calendarDateChanged(date)),
  chooseAppointment: (appointment) => dispatch(chooseAppointment(appointment)),
  loadSchedulingIfNeeded: (scheduling) => dispatch(loadSchedulingIfNeeded(scheduling)),
  setAppointmentModalMode: (mode) => dispatch(setModalMode(ModalNames.appointment, mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
